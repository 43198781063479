import React from "react";
import "./Services.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import instruments from "../../static/img/ServiceImages/instruments.jpg";
import pill from "../../static/img/ServiceImages/pill.jpg";
import surgery from "../../static/img/ServiceImages/surgery.jpg";
import meds from "../../static/img/ServiceImages/meds.jpg";

class Services extends React.Component {
  render() {
    return (
      <Container className="Home" fluid>
        <Row className="services">
          <Col sm={12} md={12} lg={6} className="service-heading">
            <h1>Services</h1>
            <div className="service-line"></div>
            <div className="line-divider mb-3"></div>
            <p>&nbsp;</p>
          </Col>

          <Col sm={12} md={12} lg={5} className="purpose pt-4">
            <h2>Purpose</h2>
            <div className="title-underline"></div>
            <p className="pt-3">
              Our supply and procurement company, Sensala FZE, provides time-critical deliveries of pharmaceutical, medical equipment and medical consumables to care providers in 3 countries. We pride ourselves on providing tailored quality solutions that benefit all of our partners.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h1 className="title-spacing">Medical Consumables</h1>
            <div className="underline-category"></div>
          </Col>
        </Row>
        <Row className="d-flex flex-row no gutters mt-4 mb-3 service-container">
          <Col sm={12} md={6} lg={6}>
              <div className="text-wrap mt-4">

              <h4>Medical practice supplies</h4>
              <div className="final-underline"></div>
              <p className="mini--box">
                From needles and syringes to gloves, masks and flu vaccine, we deliver the medical supplies you need to help keep your patients healthy. Our exceptional selection of medical products and pharmaceuticals—along with outstanding delivery service—lets you focus fully on patient care.
              </p>

              <h4>Laboratory supplies</h4>
              <div className="final-underline"></div>
              <p className="mini-text-box mt-4">
                Early detection of potential health risks with ongoing testing helps improves outcomes, reduces hospitalizations and reduces costs. Your physician office or hospital lab can tackle chronic care management and coordinate care more effectively with the help of our comprehensive line of laboratory products and equipment. Our healthcare products include test kits, clinical analyzers, petri dishes, and more
              </p>

              <h4>Surgery center supplies and services</h4>
              <div className="final-underline"></div>
              <p className="mini-text-box mt-4">
                Improve performance in your ambulatory surgery center with top-quality surgical supplies including surgical equipment, custom procedure trays, prescription drugs, IV sets and solutions. With low units of measure and a robust portfolio of products, as well as a nationwide distribution network, we’ve got your surgery center supply and equipment needs covered.
              </p>

              <h4>Pharmaceutical Distribution</h4>
              <div className="final-underline"></div>
              <p className="mini-text-box mt-4">
                When you partner with us, your pharmacy, hospital or medical practice benefits from access to a wide selection of drug products, delivered accurately, safely and on time.
              </p>

              <h4>Third-party logistics for all pharmaceutical products</h4>
              <div className="final-underline"></div>
              <p className="mini-text-box mt-4">
                Whatever your product, and whatever the size of your inventory, our 3PL services ensure accurate, safe and cost-effective distribution. Our specialised services are tailored to your needs, too. Here’s a look at how we support your specialty therapy or generic product’s commercialization from beginning to end.
              </p>

              <h4>Expert 3PL distribution and storage</h4>
              <div className="final-underline"></div>
              <p className="mini-text-box mt-4">
                Rest easy knowing that our Quality Management System ensures your products are stored according to Good Manufacturing Practice (cGMP) requirements. Our quality check complies with all federal and state regulations, including Good Storage and Distribution Practices for Drug Products (USP1079). 
              </p>

              <p>Our Verified-Accredited Wholesale Distributors (VAWD) accredited warehouse meets the standards of the Prescription Drug Marketing Act (PDMA).</p>

              <p>You’ll also benefit from:</p>
              <ul>
              <li>Satellite-tracked plasma transportation and warehousing</li>
              <li>Complete business continuity plan, including 100% site redundancy and full generator backup</li>
              <li>Secure and compliant controlled substance monitoring program</li>
              <li>License to ship products to all 50 U.S. states</li>
              <li>Same-day and after-hours shipments for critical products</li>
              </ul>
            </div>
          </Col>

          <Col sm={12} md={6} lg={6} className="pl-0 pr-0">
            <Image src={surgery} alt="" className="image-sizing" />
          </Col>
        </Row>

        <Row className="d-flex flex-row justify-content-center">
          <Col sm={18} md={6} lg={6}>
            <Card className="mb-2">
              <Card.Img
                variant="top"
                src={pill}
                className="img-fluid"
                alt="pill"
              />
              <Card.Body>
                <Card.Title>
                  <h3>
                    Pharmaceutical 
                    <br /> supply services
                  </h3>
                  <div className="mini-line"></div>
                </Card.Title>
                <Card.Text>
                  <p>Our mission as your distribution partner is to help you improve the quality of care and service you deliver to patients and customers and drive better financial results for your business.</p>
                  <p>Our breadth of drug types, accurate and timely delivery, flexibility, packaging options and reliability are just a few of the reasons to partner with us for your drug distribution needs.</p>

                  <p>other pharmaceutical products, including:</p>
                  <h4>Brand Name Drugs</h4>
                  <div className="final-underline"></div>
                  <p className="mini-text-box mt-4">
                    Brand name drugs for hospitals, health systems and pharmacies.
                  </p>

                  <h4>Generic Drugs</h4>
                  <div className="final-underline"></div>
                  <p className="mini-text-box mt-4">
                    Generic drugs for hospitals, health systems and pharmacies.
                  </p>

                  <h4>OTC Products</h4>
                  <div className="final-underline"></div>
                  <p className="mini-text-box mt-4">
                    OTC pharmaceutical, health and beauty products for pharmacies of all types and sizes.
                  </p>

                  <h4>Specialty Drugs for Physician Practices</h4>
                  <div className="final-underline"></div>
                  <p className="mini-text-box mt-4">
                    Specialty physician practices access brand and generic oral, infusible and injectable specialty medications.
                  </p>

                  <h4>Vaccines</h4>
                  <div className="final-underline"></div>
                  <p className="mini-text-box mt-4">
                    Vaccine supply, purchasing and distribution services for retail and institutional pharmacies.
                  </p>

                  <h4>Plasma & Biologics for Pharmacies and Health Systems</h4>
                  <div className="final-underline"></div>
                  <p className="mini-text-box mt-4">
                    Hospitals, health systems and pharmacies access plasma, biologics and other specialty drugs.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={18} md={6} lg={6}>
            <Card className="mb-2">
              <Card.Img
                variant="top"
                src={instruments}
                className="img-fluid"
                alt="instruments"
              />
              <Card.Body>
                <Card.Title>
                  <h3>
                  Pharmacy
                    <br />  Technology and Support
                  </h3>
                  <div className="mini-line"></div>
                </Card.Title>
                <Card.Text>
                  <p>Optimise your pharmacy’s drug spend :</p>
                  <h4>Drug Ordering & Support</h4>
                  <p>Meet your wholesale drug purchasing and distribution needs</p>

                  <h4>Controlled Substance Ordering (CSOS)</h4>
                  <p>Order and receive controlled substances securely and efficiently and comply with DSCSA tracking and reporting requirements.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="d-flex flex-row pt-4">
          <Col md={6} lg={6}>
            <h1>
            Healthcare <span className="green">insights</span>
            </h1>
            <div className="final-underline"></div>
            <p className="help-paragraph">
              The purpose of this unit is to source new data and analytics products for our suppliers. In providing new and unique healthcare insights to pharmaceutical managers and executives, we hope to build on our relationships and improve clinical outcomes.
            </p>
            <h4>Who it is for</h4>
            <p className="help-paragraph">
              Manufacturer, payor, provider, patient
            </p>
            <h4>Why it can help</h4>
            <p className="help-paragraph">
              Building sustainable, long term, cost optimised models of patient centred care.
            </p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Image src={meds} className="med-style" alt="meds" fluid />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Services;
