import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";

import research from "../static/img/Home/research1.jpg";

class Credits extends React.Component {
  render() {
    return (
      <Container className="Home" fluid>
        <Row className="d-flex flex-row no gutters justify-content-md-center">
          <Col sm={12} md="auto" lg={6}>
            <h1>Credits</h1>

            <p>This website has evolved from a project of the following students:</p>
            <ul>
              <li><a href="https://www.linkedin.com/in/chandni-janachandran/">Chandni Janachandran</a> (
                <a href="https://github.com/ChandniKJ">github</a> - <a href="mailto:chandni.janachandran@gmail.com">email</a>
              )</li>
              <li><a href="https://www.linkedin.com/in/vicky-leandrabarnard/">Vicky-Leandra Teixeira</a> (
                <a href="https://github.com/Vickyleandra">github</a> - <a href="mailto:vickyleandra5@gmail.com">email</a>
              )</li>
              <li><a href="https://www.linkedin.com/in/mollie-ball">Mollie Ball</a> (
                <a href="https://github.com/mollieball">github</a> - <a href="mailto:mollieball@squarepeg.ie">email</a>
              )</li>
              <li><a href="https://www.linkedin.com/in/andrea-de-roeck-b6b47164/">Andrea de Roeck</a> (
                <a href="https://github.com/dreaderoeck">github</a> - <a href="mailto:deroeckandrea@gmail.com">email</a>
              )</li>
              <li><a href="https://www.linkedin.com/in/angelinerocha/">Angeline Rocha</a> (
                <a href="https://github.com/angiellas">github</a> - <a href="mailto:angeline@outlook.com.br">email</a>
              )</li>
            </ul>
            <p>Their work was a part of their classes at <a href="https://www.wildcodeschool.com/fr-FR">Wild School</a> in <a href="https://www.wildcodeschool.com/en-IE/campuses/dublin">Dublin campus</a>. The training was overseen remotely from <a href="https://www.wildcodeschool.com/fr-FR/campus/amsterdam">Amsterdam campus</a> following restrictions and adjusments to Covid-19 crisis. We would like to acknowledge the contributions and thank the managment and professors of these two campuses: Marek Wystepek and Arnaud Dellinger (from Dublin) as well as Edo Molhoek and Malcolm Kente (from Amsterdam).</p>
            <p>The following art and media creations have been used in our website according to their license published by their author:</p>
            <ul>
            <li><a href="https://pixabay.com/vectors/pharmacogenomics-pharmacogenetics-2316521/">Pharmacogenomics Pharmacogenetics</a> by <a href="https://pixabay.com/users/mcmurryjulie-2375405/">Julie McMurry</a> on <a href="https://pixabay.com">PixaBay</a></li>
            <li><a href="https://pixabay.com/illustrations/medicine-pharmaceuticals-pill-bottle-2801025/">Medicine Pharmaceuticals Pill</a> by <a href="https://pixabay.com/users/mcmurryjulie-2375405/">Julie McMurry</a> on <a href="https://pixabay.com">PixaBay</a></li>
            </ul>
          </Col>
          <Col sm={12} md="auto" lg={6} className="portal-style">
            <Image src={research} className="portal-image" height="50%" alt="" fluid />
          </Col>
        </Row>

      </Container>
    );
  }
}

export default Credits;
