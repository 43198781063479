import React from "react";
import { Switch, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NavHeader from "./components/NavHeader/NavHeader";
import Footer from "./components/Footer/Footer";

import Home from "./InformationPages/Home/Home";
import Services from "./InformationPages/Services/Services";
import Credits from "./InformationPages/Credits";
import Contact from "./InformationPages/Contact/Contact";

import "./App.css";

function App() {
  return (
    <Container className="container-style pl-0 pr-0" fluid>
      <Row className="no-gutters">
        <Col className="pr-0 mr-0 mb-5 pb-5 pl-0 pr-0">
          <NavHeader />
        </Col>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/contact" component={Contact} />
          <Route path="/credits" component={Credits} />
        </Switch>
      </Row>
      <Footer />
    </Container>
  );
}

export default App;
