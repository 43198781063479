import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import group_logo from "../../static/img/MedFocus-Icon.png";

function Footer() {
  return (
    <div className="Footer flex-direction">
      <p>
        <Link to="/">
          <img src={group_logo} alt="Logo" className="logo" />
          © 2017-2020 MedFocus Group. 
        </Link> All rights reserved (<Link to="/credits">credits</Link>).
      </p>
    </div>
  );
}

export default Footer;
