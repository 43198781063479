import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import "./NavHeader.css";
import group_logo from "../../static/img/MedFocus-Logo.png";

class NavHeader extends React.Component {
  render() {
    return (
      <div>
        <Navbar collapseOnSelect expand="lg" className="NavHeader">
        <Navbar.Brand href="/"><img src={group_logo} width="300" alt="Logo" className="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="container-fluid ml-auto flex-lg-row align-items-center">
              <Nav.Item>
                <Nav.Link>
                  <NavLink to="/services">Services</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <NavLink to="/contact">Contact</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="ml-auto">
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default NavHeader;
