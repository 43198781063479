import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Contact.css";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

class Contact extends React.Component {
  render() {
    return (
      <Container className="Contact mt-3 mb-5" fluid>
        <Row>
          <Col>
            <h1 className="title-spacing">Contact Us</h1>
            <div className="underline-contact"></div>
          </Col>
        </Row>
        <Row>
          <Col md={7} lg={7} className="form pt-5">
            <Form
              action="mailto:info@medfocusgroup.com"
              method="get"
              enctype="text/plain"
            >
              <Form.Row>
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Name</Form.Label>
                  <FormControl placeholder="First Name" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridLastName">
                  <Form.Label>Last Name</Form.Label>
                  <FormControl placeholder="Last Name" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridCompany">
                  <Form.Label>Company</Form.Label>
                  <FormControl placeholder="Company Name" />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridSubject">
                  <Form.Label>Subject</Form.Label>
                  <FormControl placeholder="Subject" />
                </Form.Group>
              </Form.Row>
              <Form.Group controlId="exampleForm.ControlTextarea">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows="3" />
              </Form.Group>
              <div className="btn-holder">
                <Button className="form-btn" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
          <Col className="centre-container" sm={12} md={4} lg={4}>
          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1Czw2EqUJ9ZhKKELhgP1oRois_iQC2X9u" width="100%" height="400" title="Map"></iframe>
                  </Col>
        </Row>

        <Row><Col>

        
        </Col></Row>
      </Container>
    );
  }
}

export default Contact;
