import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

import sensalaPortal from "../../static/img/Home/sensalaPortal.jpg";
import patientIcon from "../../static/img/Home/patient.svg";
import hospitalIcon from "../../static/img/Home/hospital.svg";
import agileIcon from "../../static/img/Home/agileService.svg";
import medicalImage from "../../static/img/Home/medical-supplies.jpg";
import tablets from "../../static/img/Home/tablets.jpg";
import research from "../../static/img/Home/research1.jpg";
import complianceIcon from "../../static/img/Home/compliance.svg";
import "./Home.css";

class Home extends React.Component {
  render() {
    return (
      <Container className="Home" fluid>
        <Row className="d-flex flex-row no gutters justify-content-md-center">
          <Col sm={12} md="auto" lg={6}>
            <h1>About Us</h1>
            <div className="mini-line"></div>
            <p className="mini-text-box mt-4">
              At MedFocus Group, we’re committed to keeping that simple promise, every day. We work with principals, Hospitals and pharmacies to shape our services around their requirements. We get your products to the people who need them – your patients.
            </p>
            <p>
              Our business began with supply chain and procurement services and has grown to include an ever-increasing array of specialities. Our healthcare sector expertise and experience in handling specialist products, as well as our position within a leading international healthcare group, makes us a dependable long-term partner for your business.
            </p>
            <p>
                <Link to="/services">
                  <Button className="">Click for Service Information</Button>
                </Link>
            </p>
          </Col>
          <Col sm={12} md={12} lg={1} className="pl-0 pr-0">
            <div className="line-animation mb-3"></div>
          </Col>
          <Col sm={12} md="auto" lg={5}>
            <Image src={sensalaPortal} className="portal-image" alt="" fluid />
          </Col>
        </Row>
        {/* Scroll down Section  */}
        <Row>
          <Col
            sm={true}
            md={12}
            lg={12}
            className="arrow-style d-flex flex-column align-items-center"
          >
            <p>
              Scroll down <br /> for more information
            </p>
            <svg
              id="i-chevron-bottom"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="20"
              height="20"
              fill="none"
              stroke="rgb(208,208,208)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
            >
              <path d="M30 12 L16 24 2 12" />
            </svg>
            <svg
              id="i-chevron-bottom"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="20"
              height="20"
              fill="none"
              stroke="rgb(208,208,208)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
            >
              <path d="M30 12 L16 24 2 12" />
            </svg>

            <svg
              id="i-chevron-bottom"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="20"
              height="20"
              fill="none"
              stroke="rgb(208,208,208)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
            >
              <path d="M30 12 L16 24 2 12" />
            </svg>
          </Col>
        </Row>

        {/* Scope of Service Section  */}

        <Row className="d-flex flex-row no gutters mt-4 mb-3 service-container">
          <Col sm={12} md={6} lg={6}>
              <div className="text-wrap">
              <h3>History</h3>
              <div className="mini-line"></div>
              <p className="mini-text-box mt-4">
                The MedFocus Group Chemical Co. was established in 2017 by a group of pharmacists and private investors with a strong network of global medical and pharmaceutical manufacturers. An outsourced, end-to-end medical supply, procurement and inventory management solution.
              </p>
              <h3>Management</h3>
              <div className="mini-line"></div>
              <p className="mini-text-box mt-4">
                Our Management are a vastly experienced team of professionals working to oversee our performance targets and strategic goals.
              </p>
              <h3>People</h3>
              <div className="mini-line"></div>
              <p className="mini-text-box mt-4">
                Here at MedFocus Group we are committed to creating a high performance culture, where our people are encouraged to professionally develop in line with our core corporate values of Quality, Partnership, Expertise and innovation.
              </p>
            </div>
          </Col>

          <Col sm={12} md={6} lg={6} className="pl-0 pr-0">
            <Image src={medicalImage} alt="" className="image-sizing" />
          </Col>
        </Row>


        {/* Collaborative Section  */}
        <Row
          className=" d-flex flex-row
         no gutters mt-2"
        >
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex flex-column align-items-center"
          >
            <div className="colour-wrapper">
              <Image src={patientIcon} alt="" className="icons" fluid />
            </div>
            <div className="collab-style-line"></div>
            <p className="mini-text-style">
              Patient <br /> Centered
            </p>
          </Col>
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex flex-column align-items-center"
          >
            <div className="colour-wrapper">
              <Image src={agileIcon} alt="" className="icons" fluid />
            </div>
            <div className="collab-style-line"></div>
            <p className="mini-text-style">
              Agile Organisational <br />
              Ethos
            </p>
          </Col>
          <Col
            sm={12}
            md={4}
            lg={4}
            className="d-flex flex-column align-items-center"
          >
            <div className="colour-wrapper">
              <Image src={hospitalIcon} alt="" className="icons" fluid />
            </div>
            <div className="collab-style-line"></div>
            <p className="mini-text-style">
              Client Engagement
            </p>
          </Col>
        </Row>


        {/* Regional Section  */}
        <Row className="pl-0 pr-0 mb-5 no gutters justify-content-center">
          <Col
            sm={12}
            md={8}
            lg={4}
            className="green-background pb-3 mb-3 pl-4"
          >
            <div className="text-box-style pt-3">
              <h4>Challenges and <span className="green">Solutions</span></h4>
              <div className="text-box-underline"></div>
              <div className="mini-text-box mt-4">
                <p>
                  With the onset of the current global pandemic, healthcare facilities face an enormous challenge in providing patients with consistent, value based care. Through our supply company, Sensala, we provide a robust and cost efficient supply and procurement solution to meet care provider demands.
              </p>
              </div>
            </div>
          </Col>

          <Col sm={4} md={4} lg={2} className="pl-0 pr-0 mb-3">
            <Image src={research} alt="research image" className="image-fill" />
          </Col>

          <Col sm={12} md={8} lg={4} className="green-background pb-3 mb-3">
            <div className="text-box-style pt-3">
              <h4>Corporate Social Responsibility</h4>
              <div className="text-box-underline"></div>
              <div className="mini-text-box mt-4">
                <p>
                  We recognise the importance of Corporate Social Responsibility (CSR) in the way we manage our business. We understand our responsibilities to our employees, customers & suppliers, shareholders, the environment & the community at large.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} className="pl-0 pr-0 mb-3">
            <Image src={tablets} alt="research image" className="image-fill" />
          </Col>
        </Row>

        {/* Compliance Section */}
        <Row className="pl-0 pr-0 mb-5 no gutters justify-content-center">
            <Col sm={12} md={6} lg={7} className="mt-4 pr-3">
              <div className="compliance-box mb-4">
                <h3>Regulations</h3>
                <div className="mini-line"></div>
                <p className="mini-text-box mt-4">
                  For further information about these continue over to our
                  compliance page, which will give a full overview on everything
                  you need to know.
                </p>
              </div>
            </Col>
            <Col sm={1} md={5} lg={4} className="compliance-image">
              <Image
                src={complianceIcon}
                alt=""
                height="300"
                className="pt-5 pb-5 pr-0 pl-4 ml-3 "
              />
            </Col>
        </Row>
      </Container>
    );
  }
}
export default Home;
